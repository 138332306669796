@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.activateContainer {
  max-width: 960px;
  margin: 2rem auto;
}

.activateHeader {
  font-weight: 400;
  text-decoration: underline;
  font-size: 2rem;
}

.formMessages {
  text-align: center;
}