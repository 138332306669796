@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/forms";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.formManageAttachments {

    &__errorAnchor {
        
        &:hover, &:focus {
            color: colours.$errorTextFocus;
        }

    }

    &__li {

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            
            &:nth-child(2n + 1) {

                .formManageAttachments__wrapper {
                    margin-right: 2.25em;
                }

            }
            
            &:nth-child(2n + 2) {

                .formManageAttachments__wrapper {
                    margin-left: 2.25em;
                }

            }

        }
        
        @media #{mixins.scrMinWidth(responsive.$container_max_width)} {
            
            &:nth-child(2n + 1) {

                .formManageAttachments__wrapper {
                    margin-right: 0;
                }

            }
            
            &:nth-child(2n + 2) {

                .formManageAttachments__wrapper {
                    margin-left: 0;
                }

            }

            $desktopMargin: 2.75em;
            
            &:nth-child(3n + 1) {

                .formManageAttachments__wrapper {
                    margin-right: $desktopMargin;
                }

            }
            
            &:nth-child(3n + 2) {

                .formManageAttachments__wrapper {
                    margin-right: $desktopMargin / 2;
                    margin-left: $desktopMargin / 2;
                }

            }
            
            &:nth-child(3n + 3) {

                .formManageAttachments__wrapper {
                    margin-left: $desktopMargin;
                }

            }

        }

    }

    &__button {
        
        &:hover, &:focus {
            @extend %formButtonFocus;
        }

    }

}