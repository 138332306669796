@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.classifiedImageContainer {
  width: 150px;
  height: 150px;
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.classifiedImage {
  width: 300px;
  height: auto;
  margin-bottom: 0px;
}

.infoContainer {
  flex-grow: 1;
  padding: 1rem 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 0;
  flex-basis: 0;
}

.classifiedsType {
  margin-right: 5px;
}

.classifiedsName, .businessName {
  font-weight: 600;
  font-size: 1rem;
  color: #424242;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.location, .classifiedsInfo, .classifiedDetail {
  color: #6D6F7B;
}

.classifiedDate {
  font-size: 0.7rem;
}

.classifiedDetail {
  font-size: 0.7rem;
  height: 55px;
  overflow-y: auto;
}

.classifiedDetailBrief {
  font-size: 0.7rem;
  height: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 25px;
}

hr.separator {
  margin: 0 !important;
  opacity: 0.7;
}