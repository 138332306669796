@use "../../assets/scss/variables/accessibility";
@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/mixins";
@use "../../assets/scss/variables/responsive";

$dialogMarginMdHoriz: 20px;

.comingSoonDialog {
    padding: $dialogMarginMdHoriz;

    @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
        max-width: 700px;
        margin: 0 auto;
    }

    &__logo {
        width: 86%;
        max-width: 320px;
        margin: {
            top: 4px;
            bottom: 6px;
        }

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            // width: $dialogWidthMd;
            max-width: 422px;
            display: block;
            margin: {
                top: 22px;
                right: auto;
                bottom: 18px;
                left: auto;
            }
        }

    }

    &__heading {
        font-size: 1.3rem;

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            font-size: 1.7rem;
            text-align: center;
        }

    }

    %paragraph {
        margin-bottom: 0.8rem;
        font-size: 0.9rem;
        line-height: 1.3;
    }

    &__paragraph {
        @extend %paragraph;

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            font-size: 0.75rem !important;
            line-height: 1.75 !important;
            text-align: center;
        }

    }

    &__loadingMessage {
        width: 100%;
        height: 88px;
        position: relative;

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            margin: {
                top: 1.75rem;
                bottom: 1.5rem;
            }
        }

        @keyframes rotateSpinner {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        &__spinnerContainer {
            width: 50px;
            height: 50px;
            margin-left: 0 - (50px / 2);
            position: absolute;
            left: 50%;
            top: 0;
            animation-name: rotateSpinner;
            animation-duration: 0.75s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            &__svg {
                width: 100%;
                height: 100%;
            }

            &__path {
                fill: colours.$green;
            }

        }

        &__message {
            margin-bottom: 0;
            padding-top: 3.5rem;
            font-size: 0.8rem;
            line-height: 1rem;
            text-align: center;
        }

    }

    &__submittedMessage {
        @extend %paragraph;
        font-weight: bold;
        color: colours.$green;

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            margin: {
                top: 1.5rem;
                bottom: 1.5rem !important;
            }
            font-size: 0.75em !important;
            text-align: center;
        }

        &--error {
            color: colours.$errorTextFocus;

            a {
                color: colours.$errorTextFocus;
            }

        }

    }

    &__form {

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            width: 458px;
            margin: {
                top: 1.8rem;
                right: auto;
                left: auto;
            }
            padding-bottom: 1.3rem;
            position: relative;
        }

        &__formErrorList {
            color: colours.$errorText;

            &__alert {
                @extend %paragraph;

                @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                    font-size: 0.75rem !important;
                    text-align: center;
                }

            }

            &__errorList {
                margin: {
                    top: 0;
                    right: 0;
                    bottom: 0;
                }

                @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                    margin-left: 0;
                    text-align: center;
                    list-style-position: inside;
                }

                &__error {
                    @extend %paragraph;

                    @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                        font-size: 0.75rem !important;
                    }

                    &__anchor {
                        color: colours.$errorText;
                    }

                }
                
            }

        }

        &__fieldset {
            margin-bottom: 0;
            border-width: 0;

            &__formInputText {
                font-weight: normal;

                &__label {
                    margin: {
                        left: 1.25rem;
                    }
                    @extend %paragraph;
                    display: inline-block;
                    font-weight: bold;

                    @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                        margin-bottom: 0.4rem !important;
                        font-size: 0.75rem !important;
                    }

                }

                &__input {
                    width: 100%;
                    padding: 0.5rem 1.25rem;
                    font-size: 0.9rem;
                    border: {
                        width: 2px;
                        style: solid;
                        color: colours.$black;
                        radius: 6px;
                    }

                    @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                        width: 346px;
                        font-size: 0.75rem;
                    }

                }

                &__formErrorMessage {
                    @extend %paragraph;
                    margin: {
                        top: 0.8rem;
                        bottom: 0 !important;
                        left: 1.25rem;
                    }
                    font-weight: bold;
                    color: colours.$errorTextFocus;

                    @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                        margin-bottom: 0 !important;
                        font-size: 0.75rem !important;
                        position: absolute;
                        bottom: 0;
                    }

                }

            }

        }

        &__submit {
            margin: {
                top: 0;
                left: 1.25rem;
            }
            padding: 0.5rem 1rem;
            text-transform: uppercase;
            color: colours.$white;
            border: {
                width: 0;
                style: solid;
                color: colours.$green;
                radius: 8px;
            }
            background-color: colours.$green;
            cursor: pointer;

            @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                padding: {
                    top: 0.62rem;
                    bottom: 0.62rem;
                }
                position: absolute;
                right: 0;
                // bottom: 1.3rem;
                bottom: 2.3rem;
            }

        }

    }

    &__buttonFlex {

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            display: flex;
            justify-content: center;
        }

    }

    &__button {

        &__dont {
            padding: 0;
            @extend %paragraph;
            color: colours.$green;
            text-decoration: underline;
            border-width: 0;
            background-color: transparent;
            cursor: pointer;

            @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                margin: {
                    right: auto;
                    left: auto;
                }
                // display: block;
                display: inline-block;
                font-size: 0.75rem !important;
            }

        }

    }

}