@use "../../assets/scss/variables/accessibility";
@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/forms";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.tooltip {

    &__launch {

        &:hover, &:focus {
            background-color: colours.$formInputFocusGreen;
        }

    }

    &__tip {

        &__close {

            .closeButton {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }

            &:hover, &:focus {
                
                .closeButton {

                    path {
                        fill: colours.$formInputFocusGreen;
                    }

                }

            }

        }

        &--offsetLeft {
            margin-left: 0;
            left: -0.75em;

            .tooltip {

                &__tip {

                    &__arrow {
                        margin-left: 0;
                        left: 1em;
                    }

                }

            }

        }

        &--offsetRight {
            margin-left: 0;
            left: auto;
            right: -0.75em;

            .tooltip {

                &__tip {

                    &__arrow {
                        margin-left: 0;
                        left: auto;
                        right: 1em;
                    }

                }

            }

        }

    }

}