@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/forms";
@use "../../assets/scss/variables/mixins";
@use "../../assets/scss/variables/responsive";

.formManageAttachments {

    &__errorParagraph {
        margin-bottom: 0.75em;
    }

    &__errorAnchor {
        color: colours.$errorText;
    }

    &__validated {
        @extend %validationMessage;
    }

    &__fieldset {
        border-width: 0;
    }

    &__legend {
        @extend %label;
    }

    &__list {
        width: 16.8em;
        margin: {
            top: 1.5em;
            right: auto;
            bottom: 0;
            left: auto;
        }

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
        }

    }

    &__li {
        margin: 2.5em 0;
        list-style-type: none;

        &:first-child {
            margin-top: 0;
        }

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            width: 50%;
            margin: {
                top: 0;
                bottom: 2em;
            }
        }

        @media #{mixins.scrMinWidth(responsive.$container_max_width)} {
            width: 33%;
        }

    }

    &__thumbnail {
        width: 100%;
        margin: {
            top: 0.5em;
            bottom: 1em;
        }
        display: block;
        border: 1px solid colours.$black;
    }

    &__button {
        @extend %formButton;
        width: 16.8em;
        margin: {
            right: auto;
            left: auto;
        }
        display: block;
    }

}