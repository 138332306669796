@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.confirmContainer {
  max-width: 960px;
  margin: 2rem auto;
}

.confirmHeader {
  font-weight: 400;
  text-decoration: underline;
  font-size: 1.125rem;
}

.resendLink {
  text-decoration: underline;
  cursor: pointer;
}
