@use '../../../assets/scss/variables/accessibility';
@use '../../../assets/scss/variables/colours';
@use '../../../assets/scss/variables/forms';
@use '../../../assets/scss/variables/measurements';
@use '../../../assets/scss/variables/mixins';
@use '../../../assets/scss/variables/responsive';
@use '../../../assets/scss/variables/typography';

.headerHub {

    &__menu {
        
        .hamburger {
            width: 100px;
            height: 100px;

            path {
                fill: colours.$black;
            }

        }

    }

}