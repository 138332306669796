@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.half {
  width: 50%;
}

.full {
  width: 100%;
}

.fieldLabel {
  font-weight: 700;
  margin-top: 0.5rem;
}

.fieldError {
  color: #e74c3c;
  font-size: 14px;
}

.fieldInput {
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  border: 1px solid #D6D6D6;
  width: 100%;
  margin: 0.5rem auto 1rem;
}
select.fieldInput {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;  
  background: white url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 100% 50%;
}

.fieldTallTextArea {
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  border: 1px solid #EEE;
  width: 100%;
  margin: 0.5rem auto 1rem;
}

@media only screen and (max-width: 511px) {

  .half {
    width: 100%;
  }

}

/* IE10+ CSS styles */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  
  select.fieldInput {
    background-image: none;
  }
  
}
