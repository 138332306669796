@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.hero {
  color: white;
  text-align: left;
  padding: 2rem;
  height: 600px;
  padding-top: 12rem;
  margin-top: -4rem;

  &--loggedIn {

    @media #{mixins.scrMinWidth(responsive.$desktop_min_width)} {
      margin-top: 0;
    }

  }

  &--loggedInManager {

    @media #{mixins.scrMinWidth(responsive.$desktop_min_width)} {
      margin-top: -4em;
    }

  }
}

.heroInfo {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
}

.tagLine {
  width: 100%;
  max-width: 550px;
}

.discover, .classifiedsContainer, .shippingContainer {
  width: 95vw;
  max-width: 1160px;
  margin: 0 auto;
  padding: 2rem 0;
}

.discoverHeader {
  text-align: left;
}

.center {
  text-align: center;
}

.cta {
  padding: 3rem 0 0;
}

hr.centerLine {
  border: 0;
  border-top: 5px solid #00703C;
  width: 30vw;
  max-width: 200px;
  margin: -0.5rem auto 2rem;
}

.shipping {
  height: 360px;
  color: white;
}

.shippingHeader {
  padding-top: 1rem;
}

.classifieds {
  background: #F5F4F5;
}

.shadow {
  text-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.minWidth {
  min-width: 240px;
}

.scrollToTopContainer::after {
  content: "";
  clear: both;
  display: table;
}

.scrollToTop {
  color: #6D6F7B;
	cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
	float: right;
}
