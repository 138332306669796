@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.pageContainer {
  max-width: 1160px;
  margin: 2rem auto;
}

.title {
	color: #3d7f08;
	font-size: 1.125rem;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.scrollToTop {
	color: #6D6F7B;
	cursor: pointer;
	margin-top: 20px;
	float: right;
}
