@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.greyBox {
  background-color: #F3F3F3;
  border-radius: 4px;
  padding: 4px 10px;
  border: 1px solid #E2E2E2;
  margin-bottom: 5px;
  width: 100%;
}

.label {
  text-transform: uppercase;
  font-size: 12px;
  color: #AFAFAF;
}

.fieldInput {
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  border: 1px solid #D6D6D6;
  width: 100%;
  margin: 0.5rem auto 1rem;
}

.input { /* This class is needed to customize the input box in the Autosuggest widget */
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  border: 1px solid #EEE;
  width: 100%;
  margin: 0.5rem auto 0;
}

.suggestionsList {  /* This class is needed to customize the input box in the Autosuggest widget */
  margin: 0;
  padding: 0;
  list-style-type: none;
}