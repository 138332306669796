/* ******************* COLOURS ******************* */

$white: hsl(0, 0%, 100%);
$black: hsl(0, 0%, 0%);
$green: hsl(147, 75%, 24%);
$midGreen: hsl(125, 47%, 47%);
$textBlack: hsla(0, 0%, 0%, 0.8);
$cardTextBlack: hsla(0, 0%, 0%, 0.87);
$errorText: hsl(0, 100%, 10%);
$errorTextFocus: hsl(0, 100%, 50%);
$dialogOverlayBackground: hsla(0, 0%, 100%, 0.8);
$formInputTextGrey: hsl(0, 0%, 84%);
$formInputFocusGreen: hsla(147, 75%, 24%, 0.75);
$formInputFocusGreenLight: hsla(147, 75%, 24%, 0.5);
$formRuleGrey: hsl(0, 0%, 88%);
$navigationHoverGrey: hsla(0, 0%, 0%, 0.1);
$backgroundGrey: hsl(0, 0%, 96%);