@use '../../../assets/scss/variables/accessibility';
@use '../../../assets/scss/variables/colours';
@use '../../../assets/scss/variables/forms';
@use '../../../assets/scss/variables/measurements';
@use '../../../assets/scss/variables/mixins';
@use '../../../assets/scss/variables/responsive';
@use '../../../assets/scss/variables/typography';

.headerHub {

    &__menu {
        width: 24px;
        height: 24px;
        padding: 0;
        overflow: hidden;
        border-width: 0;
        background-color: transparent;
        cursor: pointer;

        @media #{mixins.scrMinWidth(responsive.$desktop_min_width)} {
            display: none;
        }

    }

    &__navigation {
        position: absolute;
        right: 0;
        top: 3.2em;
        background-color: colours.$white;
        display: none;

        @media #{mixins.scrMinWidth(responsive.$desktop_min_width)} {
            width: 100%;
            height: 80px + 80px;
            background-color: transparent;
            top: 0;
            right: auto;
        }

        &--open {
            display: block;
        }

    }

    &__ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        box-shadow: hsla(0, 0%, 0%, 0.12) 0px 1px 6px, hsla(0, 0%, 0%, 0.12) 0px 1px 4px;

        @media #{mixins.scrMinWidth(responsive.$desktop_min_width)} {
            width: 100%;
            height: 100%;
            overflow: hidden;
            box-shadow: none;
            position: relative;
        }

    }

}