@use "../../../../assets/scss/variables/colours";
@use "../../../../assets/scss/variables/forms";
@use "../../../../assets/scss/variables/responsive";
@use "../../../../assets/scss/variables/mixins";

.formProductSelectorProfileProducts {
    margin: {
        top: 1em;
        bottom: 1em;
    }

    &__errorParagraph {
        margin-bottom: 0.75em;
    }

    &__errorAnchor {
        color: colours.$errorText;
    }

    &__validated {
        @extend %validationMessage;
    }

    &__suggestionsContainer {
        position: relative;
    }

    &__suggestionsList {
        width: 100%;
        height: 1px;
        overflow: visible;
        position: absolute;
        left: 0;
        bottom: 1em;
        z-index: 2;
    }

    &__suggestionsUlWrap {
        width: 100%;
        margin: 0;
        padding: 0;
        max-height: 6.7em;
        overflow-y: auto;
        border-radius: 0.2em;
        border: 1px solid #d6d6d6;
        background-color: colours.$white;
    }

    &__suggestionsUl {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &__suggestionsLi {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    &__suggestion {
        width: 100%;
        min-height: 2.5em;
        margin: 0;
        padding: 0 0.5em;
        text-align: left;
        font-size: 16px;
        line-height: 2.5em;
        border-width: 0;
        background-color: colours.$white;
        cursor: pointer;

        &:hover, &:focus {
            color: colours.$white;
            background-color: colours.$green;
        }

    }

    &__button {
        @extend %formButton;
        width: 16.8em;
        margin: {
            right: auto;
            left: auto;
        }
        display: block;
    }

    &__categories {

        &__list {
            padding-right: 1.8em;

            @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                width: 45%;
                margin-top: 0;
                position: relative;
                left: 1.3em;
            }
            
            @media #{mixins.scrMinWidth(responsive.$container_max_width)} {
                padding-right: 0;
                columns: 2;
            }

        }

        &__item {
            margin: {
                right: 0;
            }
            padding: {
                top: 0.25em;
                bottom: 0.25em;
            }
            line-height: 1.75rem;
            
            @media #{mixins.scrMinWidth(responsive.$container_max_width)} {
                padding-right: 2.1em;
            }

        }
        
        &__textButtonWrapper {
            display: inline;
            position: relative;
        }

        &__button {
            @extend %formButton;
            width: auto;
            /*
            margin: {
                left: 0.5em;
            }
            */
            padding: {
                top: 0;
                right: 0.5em;
                bottom: 0;
                left: 0.5em;
            }
            display: inline-block;
            position: absolute;
            right: -2.15em;
            bottom: -0.25em;
        }

    }

    &--withProduct {

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
        }

        .formProductSelectorProfileProducts {

            &__form {

                @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                    width: 50%;
                }

            }

        }

    }

}