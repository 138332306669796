@use "../../assets/scss/variables/accessibility";
@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/mixins";
@use "../../assets/scss/variables/responsive";

.dialogContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;

    &__background {
        width: 100%;
        height: 100%;
        background-color: colours.$dialogOverlayBackground;
        /*
        background: {
            color: colours.$white;
            image: url("../../assets/images/background_coming_soon_mobile.jpg");
            repeat: no-repeat;
            position: center top;
        }
        */
        position: absolute;
        left: 0;
        top: 0;

        /*
        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            background-image: url("../../assets/images/background_coming_soon_desktop.jpg");
        }
        */

    }
    
    $dialogWidth: 94%;
    $dialogHeight: 480px;
    $dialogMarginHoriz: 3%;
    $dialogWidthMd: responsive.$tablet_ipad_portrait_min_width - (19 * 2);
    $dialogMarginMdHoriz: 20px;

    &__dialog {
        width: $dialogWidth;
        align-self: center;
        background-color: colours.$white;
        border: {
            width: 6px;
            style: solid;
            color: colours.$green;
            radius: 10px;
        }
        position: relative;
        z-index: 1000;

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            // width: $dialogWidthMd;
            max-width: 852px;
        }

        &__button {

            &__close {
                width: 32px;
                height: 32px;
                padding: 0;
                border-width: 0;
                background-color: transparent;
                position: absolute;
                right: $dialogMarginMdHoriz / 2;
                top: $dialogMarginMdHoriz / 2;
                cursor: pointer;

                @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
                    right: $dialogMarginMdHoriz;
                    top: $dialogMarginMdHoriz;
                }

                svg {
                    width: 100%;
                    height: 100%;

                    path {
                        fill: colours.$green;
                    }
                    
                }

                &:hover, &:focus {

                    svg {

                        path {
                            fill: colours.$black;
                        }
                        
                    }
                    
                }

                &__paragraph {
                    @extend %visually-hidden;
                }

            }

        }

    }

}