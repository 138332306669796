@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.container {
    padding: 24px 0;
}

.settingsHeader4 {
    margin-top: 40px;
    margin-bottom: 0;
}
