@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.fieldLabel {
  margin: 0.5rem 0.5rem;
  font-size: 16px;
}

.fieldError {
  color: #e74c3c;
  font-size: 14px;
}

.fieldInput {
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  border: 1px solid #D6D6D6;
  margin: 0.5rem auto 1rem;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}