@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.listButton {
  background-color: #F3F3F3;
  border-radius: 4px;
  padding: 4px 10px;
  border: 1px solid #E2E2E2;
  margin-bottom: 5px;
  height: 58px;
  align-items: center;
  display: flex;
}

.listButton:not(.disabled):hover {
  border-color: #32CD32;
  transition: 0.4s;
}

.category {
  text-transform: uppercase;
  font-size: 12px;
  color: #AFAFAF;
  margin-bottom: -5px;
  cursor: pointer;
  float: right;
}

.selectedCategory {
  background-color: #F3F3F3;
  border-radius: 4px;
  padding: 4px 10px;
  border: 2px solid #00703C;
  margin-bottom: 5px;
  height: 58px;
  align-items: center;
  display: flex;
}

