@use "./assets/scss/variables/colours";
@use "./assets/scss/variables/responsive";
@use "./assets/scss/variables/mixins";

body {
  margin: 0;
  padding: 0;
}

.editButton {
  float: right;
  margin-top: -0.2rem;
}

.headerEditButton {
  float: right;
  margin-top: -3rem;
}

ul.oneColList {
  columns: 1;
  list-style-position: outside;
}

ul.twoColList {
  columns: 2;
  list-style-position: outside;
}

label.fieldLabel {
  font-weight: 700;
  margin: 0.5rem auto 0.3rem;
}

.textLink {
  text-decoration: underline;
  cursor: pointer;
}

.hoverable:hover {
  transform: translateY(-5px);
  transition: all 650ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.hoverable:hover > a > div {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 2px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important;
}

a.noUnderline {
  text-decoration: none !important;
}

a {
  color: rgba(0, 0, 0, 0.8);
}

.container-fluid {
  padding-left: 1rem;
  padding-right: 1rem;
}

.noMarginBottom {
  margin-bottom:  0 !important;
}
