@use "./colours";
@use "./mixins";
@use "./responsive";
@use "./typography";

%container {
    max-width: responsive.$container_max_width;
    margin: 0 auto 6em;
}

%block {
    padding: 2em;
    font-family: typography.$openSansHelv;
    color: colours.$cardTextBlack;
    border-radius: 0.1em;
    box: {
        sizing: border-box;
        shadow: rgb(0 0 0 / 16%) 0px 0.5em 1em, rgb(0 0 0 / 23%) 0px 0.5em 1em;
    }
    background-color: colours.$white;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

%heading {
    font: {
        size: 1.125rem;
        weight: 700;
    }
}

%validationMessage {
    font-weight: 700;
    color: colours.$green;
}

%flex {

    @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
    }

}

%flexFormInputText {
    
    @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
        width: 50%;
    }

    &:nth-child(odd) {

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            padding-right: 0.3em;
        }

    }

    &:nth-child(even) {

        @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
            padding-left: 0.3em;
        }

    }

}

%checkboxRadioGroup {
    margin: 0;
    border-width: 0;
    margin-bottom: 0.75em;
    border-bottom: 1px solid colours.$green;
}

%legend {
    margin: {
        bottom: 1em;
    }
    font: {
        size: 1rem;
        weight: 700;
    }
}

%checkboxRadioContainer {
    margin: {
        top: 0.5em;
        bottom: 1em;
    }
}

%labelTooltip {
    padding-right: 1.4em  + 0.25;
    position: relative;
}

%labelWrapper {
    display: inline;
    position: relative;
}

%label {
    margin-top: 0.5em;
    font-weight: 700;
}

%checkboxRadioLabel {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    font-size: 1rem;
    padding-right: 0.75em;
}

%checkboxRadioInput {
    display: grid;
    grid-template-areas: "checkbox";
}

%styledCheckboxRadio {
    width: 1em;
    height: 1em;
    // display: inline-grid;
    overflow: visible;
    border: 0.1em solid colours.$black;
    position: relative;
    top: 0.2em;
}

%checkboxRadioFlexedContainer {

    @media #{mixins.scrMinWidth(responsive.$tablet_ipad_portrait_min_width)} {
        width: 33%;
    }

    /*
    @media #{mixins.scrMinWidth(responsive.$desktop_min_width)} {
        width: 25%;
    }
    */

}

%description {
    font-size: 0.85rem;
}

%formButton {
    width: 100%;
    margin: 0.5em 0 0;
    padding: 1em 0;
    display: inline-block;
    font: {
        size: 1rem;
        weight: 600;
        family: typography.$openSansHelv;
    }
    letter-spacing: 0.1em;
    text-decoration: none;
    text: {
        decoration: none;
        transform: uppercase;
    }
    color: colours.$white;
    border: {
        width: 0;
        radius: 0.2em;
    }
    box-sizing: border-box;
    outline: none;
    background-color: colours.$green;
    position: relative;
    cursor: pointer;
    transition: background-color 0.45s cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

%formButtonFocus {
    background-color: colours.$formInputFocusGreen;
}