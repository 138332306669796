@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.profileContainer {
  max-width: 960px;
  margin: 2rem auto;
}

.breadCrumbs {
  color: #A6A7AE;
  font-size: 12px;
}

.currentCrumb {
  font-weight: 600;
  color: #7E7F89;
}

.profileInfo {
  margin-top: 1rem;
}

.profileImage img {
  border-radius: 3px;
}

.profileTitle {
  font-size: 1.3rem;
}

.profileDetails {
  padding-bottom: 1rem;
  font-size: 14px;
}

.headerEditButton {
  float: right;
  margin-top: -2.5rem;
}
