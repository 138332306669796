@use "../../assets/scss/variables/colours";
@use "../../assets/scss/variables/responsive";
@use "../../assets/scss/variables/mixins";

.productName {
  margin-bottom: 0;
}

.productsTypes {
  margin-top: 0.5rem;
}

.expandCollapse {
  text-decoration: underline;
  font-style: italic;

  &:hover {
    cursor: pointer;
  }

}

li {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  margin-right: 10px;
}
